import http from "@/plugins/http";

// 上传文件
export function uploadFile(data) {
  return http({
    url: `/system/common/upload`,
    data: data,
    method: 'POST',
  })
}

// 文件下载
export function downloadFile(data) {
  return http({
    url: `/system/common/download/resource`,
    params: data,
    method: 'GET',
  })
}