import http from "@/plugins/http";
// 获取列表
export function getMenuList() {
  // return http({
  //   url: `/system/menu/treeselect`,
  //   method: "GET"
  // });
    return http({
        url: `/system/menu/getMenuTree`,
        method: "GET"
    });
}

// 新增
export function createMenu(data) {
  console.log(data);
  return http({
    url: `/system/menu`,
    data: data,
    method: "POST"
  });
}

// 详情
export function showMenu(data) {
    console.log(data);
    return http({
        url: `/system/menu/${data.menuId}`,
        method: 'GET',
    })
}

// 编辑
export function editMenu(data) {
    console.log(data);
    return http({
        url: `/system/menu`,
        data:data,
        method: 'PUT',
    })
}

// 删除
export function deleteMenu(data) {
  console.log(data);
  return http({
    url: `/system/menu/${data.menuId}`,
    method: "DELETE"
  });
}

export function getRoleMenuList(data) {
    return http({
        url: `/system/menu/roleMenuTreeselect/${data.roleId}`,
        method: "GET"
    });
}

// 移动菜单
export function moveMenu(data) {
    return http({
        url: `/system/menu/moveMenu`,
        data: data,
        method: "POST"
    });
}

export function createConfig(data) {
    return http({
        url: `/system/config`,
        data: data,
        method: "POST"
    });
}

export function showConfig(data) {
    return http({
        url: `/system/config/configKey/${data.configKey}`,
        method: "GET"
    });
}

export function editConfig(data){
    return http({
        url: `/system/config`,
        data: data,
        method: "PUT"
    });
}
