<template>
  <a-form-model
    style="background: #ffffff;min-height:900px;padding-top: 20px;"
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item ref="name" label="系统名称：" prop="name">
      <a-input
        placeholder="请输入系统名称"
        v-model="form.name"
        @blur="
          () => {
            $refs.name.onFieldBlur();
          }
        "
      />
    </a-form-model-item>
    <a-form-model-item ref="logo" label="logo：" prop="logo">
      <!-- 上传 -->
      <a-upload
        name="avatar"
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="false"
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        :before-upload="beforeUpload"
        @change="handleChange"
        :customRequest="customRequest"
      >
        <img
          style="width: 100px;height: 100px;"
          v-if="imageUrl"
          :src="imageUrl"
          alt="avatar"
        />
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">
            请上传Logo
          </div>
        </div>
      </a-upload>
    </a-form-model-item>
    <a-form-model-item
      :wrapper-col="{ span: 8, offset: 8 }"
      style="text-align: center;"
    >
      <a-button type="primary" @click="onSubmit">
        立即修改
      </a-button>
      <a-button style="margin-left: 10px;" @click="resetForm">
        取消
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
// 上传
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
import {
  createConfig,
  showConfig,
  editConfig
} from "@/api/permission/menu/index";
import { uploadFile, downloadFile } from "@/api/upload/index";
import axios from "axios";
export default {
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      form: {
        name: "",
        describe: ""
      },
      id: 0, // 系统名称配置id
      upload_id: 0, // 系统logo配置id
      rules: {
        name: [{ required: true, message: "请输入系统名称", trigger: "blur" }],
        logo: [{ required: false, message: "请上传Logo", trigger: "blur" }]
      },
      // 上传
      loading: false,
      imageFile: "",
      imageUrl: "",
      apiUrl: "http://dev.waterss.cn:9120/api/ucp"
    };
  },
  created() {
    this.getSysConfig();
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.id) {
            let _this = this;
            Promise.all([
              this.modifyConfig(
                {
                  config_key: "system_name",
                  config_name: "系统名称",
                  config_value: _this.form.name
                },
                _this.id
              )
              // this.modifyConfig({
              //   config_key: "system_logo",
              //   config_name: "系统logo",
              //   config_value: _this.imageUrl
              // }, _this.upload_id)
            ]).then(response => {
              console.log(response);
              // if (response[0].code == 200 && response[1].code == 200) {
              if (response[0].code == 200) {
                this.$store.commit("setSystemName", _this.form.name);
                // logo处理

                // 'data:image/png;base64,' + btoa(
                //         new Uint8Array(response.data)
                //                 .reduce((data, byte) => data + String.fromCharCode(byte), '')
                // );
                _this.$store.commit("setSystemLogo", _this.imageUrl);
                _this.$message.success("修改成功", function() {
                  localStorage.setItem("system_name", _this.form.name);
                  localStorage.setItem("system_logo", _this.imageUrl);
                  _this.$router.push({
                    path: "/index/auth/menu/menuManagement"
                  });
                });
              } else {
                _this.$message.error(res.msg);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getSysConfig() {
      showConfig({
        configKey: "system_name"
      }).then(res => {
        if (res.code == 200) {
          this.id = res.data?.dbId;
          if (this.id) {
            this.form.name = res.data.config_value;
          }
        }
      });

      showConfig({
        configKey: "system_logo"
      }).then(res => {
        if (res.code == 200) {
          this.upload_id = res.data?.dbId;
          if (this.upload_id) {
            this.imageFile = res.data.config_value;
            this.imageUrl =
              this.apiUrl +
              "/system/common/download/resource?name=" +
              this.imageFile;
          }
        }
      });
    },
    async modifyConfig(data, id) {
      if (id) {
        data.dbId = id;
        return await editConfig(data);
      } else {
        return await createConfig(data);
      }
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.$router.push({
        path: "/index/auth/menu/menuManagement"
      });
    },
    // 上传
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        console.log(info.file);
        // Get this url from response in real world.

        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
    customRequest(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      uploadFile(formData).then(res => {
        if (res.code == 0) {
          this.imageFile = res.data.fileName;
          this.imageUrl =
            this.apiUrl +
            "/system/common/download/resource?name=" +
            this.imageFile;
          this.loading = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  }
};
</script>

<style scoped></style>
